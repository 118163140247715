// #root {
//   height: 100vh;
//   overflow: hidden;
//   // overflow-y: hidden;
// }

#root {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
}
